import React from "react"
import { Code } from "@atlaskit/code"
const styles = require("./Pages.module.scss");

export default function Home() {
  return (
    <div className={styles.Content}>
      <h1>Introduction</h1>
      <p>
        The Prestavi API is structured around REST and provides programmatic access to several key areas of the system. It offers predictable URLs for accessing resources and uses standard built-in HTTP features such as response codes, verbs, and authentication schemes.
      </p>
      <p>
        The API accepts JSON or form-encoded request bodies and returns JSON-encoded content in all of its responses.
      </p>

      <p>If you have questions about using the API or you would like to request that we add additional data attributes or endpoints, please don’t hesitate to reach out to us at <a className={styles.Link}
        href="mailto:developers@prestavi.com">developers@prestavi.com</a></p>

      <section>
        <strong>Base URL</strong>
        <p>The base URL for the API is <Code text="https://&lt;region&gt;.api.prestavi.com" />. The <Code text="&lt;region&gt;"/> part of the URL corresponds to the region where the data in your account is stored.</p>
        <p>You can find the region for your account at <a target="_blank" className={styles.Link} href="https://app.prestavi.com/manage/developers">https://app.prestavi.com/manage/developers</a></p>
      </section>
    </div>
  )
}